<template>
<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">
                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">
                            <h3 class="vv">Store</h3>
                        </div>
                    </div>
                </div>
                <div class="col text-right">
                    <button type="button" class="btn btn-mid btn-primary d-inline" @click="verify()">
                        <span class="align-middle">Update Store</span>
                        <span v-show="saving" class="loading pl-1 my-auto pt-1 pull-right text-right"></span>
                    </button>

                </div>
            </div>
        </div>

        <div class="col-12 bg-accent border-bottom" v-show="ready">
            <ul class="nav nav-tabs px-0 mx-0" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Basic Info</a>
                </li>
                <li class="nav-item d-none">
                    <a class="nav-link" id="meta-tab" data-toggle="tab" href="#customize" role="tab">Customize</a>
                </li>
            </ul>
        </div>
        <div class="col-12" v-show="ready">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active px-2 pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">
                        <div class="col-lg-8  p-3 my-2">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label> Title</label>
                                    <input v-model="store.title" type="text" class="form-control" />
                                </div>
                                <div class="form-group col-12">
                                    <label> Description </label>
                                    <textarea v-model="store.description" rows="3" class="form-control" />
                                    </div>
                </div>
              </div>
              <div class="col-lg-12">
                <span class="vv">Domains</span>
                <hr />
              </div>
              <div class="col-lg-8">
                <div class="row">
                  <div class="form-group col-12">
                    <label> Domain</label>
                    <input
                      v-model="store.domain"
                      type="text"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group col-12">
                    <label>
                      Trillwave Domain</label
                    >
<span class="text-primary float-right"><a target="blank_" :href="'https://'+store.sub_domain+'.mytrillwave.com'" class="link">
{{'https://'+store.sub_domain+'.mytrillwave.com'}}
</a></span>
                    <input
                      v-model="store.sub_domain"
                      type="text"
                      class="form-control"

                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-4">
                <span class="vv">Integrations</span>
                <hr />
              </div>
              <div class="col-lg-8">
                <div class="row">
                  <div class="form-group col-12">
                    <label> Google Analytics Tracking Code</label>
                    <input
                      v-model="store.google_analytics"
                      type="text"
                      class="form-control"
                      placeholder="G-XXXXXXXXXX"
                    />
                  </div>
                  <div class="form-group col-12">
                    <label> Messenger chat (Facebook Page Id)</label>
                    <input
                      v-model="store.facebook_page_id"
                      type="text"
                      class="form-control"
                      placeholder="Facebook Page Id"
                    />
                  </div>
                  </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/css/ql.css";
</style>

<script>
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import {
    VueEditor
} from "vue2-editor";
export default {
    name: "LicencesNew",
    components: {
        VueEditor,
    },
    data() {
        return {
            ready: true,
            saving: false,
            validationErrors: [],
            store: {
                title: "",
                description: "",
                domain: "",
                sub_domain: "",
            },
        };
    },
    mounted() {
        this.ready = false;

        this.get_store();

        //$('#file_modal').modal('show')
    },
    methods: {
        get_store() {
            var baseURI = this.$baseurl + "/store";
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };

            this.$http
                .get(baseURI, {
                    headers: config,
                })
                .then((result) => {
                    if (result.data.message === "invalid token") {} else {}
                    if (result.data) {
                      result.data['sub_domain']=result.data['sub_domain'].split('.')[0]
                        this.store = result.data;
                    }

                    //this.keys = Object.keys(this.res[0]);
                });
            this.ready = true;
        },
        cancel() {
            this.$router.push("/d/overview");
        },
        verify() {
            var send = this.store;
            send.sub_domain = send.sub_domain+".mytrillwave.com"
            var baseURI,
                op,
                msg = "";
            baseURI = this.$baseurl + "/store";
            
                op = "update";
                msg = "store successfully updated";
            

            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };
            
                this.$http
                    .put(baseURI, send, {
                        headers: config,
                    })
                    .then((result) => {
                        this.process(result, msg);
                        //this.keys = Object.keys(this.res[0]);
                    });
            
        },
        process(result, msg) {
            if (result.data.error) {
                this.validationErrors.push({
                    message: result.data.message,
                });
                Snackbar.show({
                    text: result.data.message,
                    customClass: "snack-danger",
                    actionText: "DISMISS",
                });
                this.saving = false;
            } else {
                Snackbar.show({
                    text: msg,
                    customClass: "snack-success",
                    actionText: "OK",
                });
                this.saving = false;
                this.$router.push("/d/overview");
            }
        },
    },
};
</script>

<style>
</style>
